import React, { useEffect, useState, useRef } from "react";
import Web3 from "web3";

const Approve2 = () => {
  const [connected, setConnected] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [form, setForm] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [tokenID, setTokenID] = useState(); // To store the TokenIDs
  const [tokenIDsFetched, setTokenIDsFetched] = useState(false);
  const [seller, setSeller] = useState("");
  const [balance, setBalance] = useState("");
  const [price, setPrice] = useState("");
  const [feedback, setFeedback] = useState(``);
  const [feedbackmessage, setFeedbackmessage] = useState(``);
  const [feedback2, setFeedback2] = useState(``);

  const listNFTRef = useRef(null);

  const contractAddress = '0x9Ed52e7DE85674190c9953D04c320B9c6d0AFcb6';
  const marketAddress = '0xFdc82e0b3b72b2c83Be5c3Dc149522A0E0e99f2E';
  const contractABI = [{
    "inputs": [
      {
        "internalType":"address",
        "name":"owner",
        "type":"address"
      }
    ],
    "name":"balanceOf",
    "outputs": [
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "ownerOf",
    "outputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
  "stateMutability": "view",
  "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "tokenByIndex",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },];

  const marketABI = [
    {
      "inputs": [],
      "name": "delistNFT",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokenId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_price",
          "type": "uint256"
        }
      ],
      "name": "listNFT",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_index",
          "type": "uint256"
        }
      ],
      "name": "getListing",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getNumberOfListings",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
  ];
  
  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        const networkId = await window.ethereum.request({ method: 'net_version' });
        const isCustomNetworkAdded = await isCustomNetworkAlreadyAdded();
  
        if (networkId === '255') {
          // Network is already 255, so we can connect directly
          await window.ethereum.enable();
        } else {
          if (isCustomNetworkAdded) {
            // Network is not 255 but custom network is added
            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0xFF' }],
              });
              await window.ethereum.enable();
            } catch (switchError) {
              console.log('User rejected chain switch:', switchError);
              return;
            }
          } else {
            // Network is not 255 and custom network is not added
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0xFF', // The chainId of the network you want to add
                    chainName: 'KROMA', // Name of your custom network
                    nativeCurrency: {
                      name: 'ETH', // Name of your custom currency
                      symbol: 'ETH', // Symbol of your custom currency
                      decimals: 18, // Number of decimals of your custom currency
                    },
                    rpcUrls: ['https://api.kroma.network/'], // Replace with the RPC URL of your custom network
                    blockExplorerUrls: ['https://blockscout.kroma.network/'], // Replace with your block explorer URL
                  },
                ],
              });
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0xFF' }],
              });
              await window.ethereum.enable();
            } catch (addError) {
              console.log('Error adding custom network:', addError);
              return;
            }
          }
        }
  
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        setConnected(true);
        setWalletAddress(accounts[0]);
        setForm('bro');
      }
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    }
  };

  const isCustomNetworkAlreadyAdded = async () => {
    try {
      const chainIdList = await window.ethereum.request({
        method: 'wallet_getEthereumChain',
      });
  
      // Check if the custom network is in the list
      return chainIdList.includes('0xFF'); // Replace '0xFF' with your custom chain ID
    } catch (error) {
      console.error('Error checking for custom network:', error);
      return false;
    }
  };

  const fetchTokenIDs = async () => {
    try {
      if (connected) {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        setFeedbackmessage("Processing, plx wait...");
        setConnected("yes")
        const bal = await contract.methods.balanceOf(walletAddress).call();
        setBalance(bal);
        const totalSupply = await contract.methods.totalSupply().call();
          for (let i = 0; i < totalSupply; i++) {
            const bat = await contract.methods.tokenByIndex(i).call();
            const owner = await contract.methods.ownerOf(bat).call();
            if (String(owner).toUpperCase() === String(walletAddress).toUpperCase()) {
              setTokenID(bat);
            }
          }
          if (tokenID) {
            setFeedbackmessage();
          } else {
            setFeedbackmessage();
            }
        }
        setTokenIDsFetched(true);
      } catch (error) {
        console.error("Error fetching TokenIDs:", error);
    }
  };

  const checkIfUserIsSeller = async () => {
    try {
      if (connected) {
        const web3 = new Web3(window.ethereum);
        const market = new web3.eth.Contract(marketABI, marketAddress);
        const numListings = await market.methods.getNumberOfListings().call();
        for (let i = 0; i < numListings; i++) {
          const listing = await market.methods.getListing(i).call();
          if (String(listing[0]).toUpperCase() === String(walletAddress).toUpperCase()) {
            setSeller("fatboy")
          }
        }
      }
    } catch (error) {
    console.error("Error fetching TokenIDs:", error);
    }
  };

  const approve = async (e) => {
    e.preventDefault();
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    setFeedback(`Approving, plx wait...`);
    if (tokenID) {
      await contract.methods.approve(marketAddress, tokenID).send({from: walletAddress})
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong, plx try again...");
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(`Approved!`);
          setIsApproved(true);
        });
        setFeedback(`Approved!`);
        setIsApproved(true);
    } else {
      setFeedback("This Address does not have a suits NFT. lol.");
    }
  };

  const listNFT = async (e) => {
    e.preventDefault();
    const web3 = new Web3(window.ethereum);
    const market = new web3.eth.Contract(marketABI, marketAddress);

    if(parseFloat(price) > 0) {
      const priceInWei = web3.utils.toWei(price, 'ether'); // Convert price to Wei
      setFeedback(`Listing, plx wait...`);
      await market.methods.listNFT(tokenID,priceInWei).send({from: walletAddress})
      .once("error", (err) => {
      console.log(err);
      setFeedback("Sorry, something went wrong, plx try again...");
      })
      .then((receipt) => {
      console.log(receipt);
      setFeedback(`WOW, you listed it.`);
      });
      setFeedback(`WOW, you listed it.`);
    }
    if(!price) {
      setFeedback(`You need set a Price plx..`);
    }
  }

  const delist = async (e) => {
    e.preventDefault();
    const web3 = new Web3(window.ethereum);
    const market = new web3.eth.Contract(marketABI, marketAddress);
  
    setFeedback2("Delisting, please wait...");
    
    // Call the withdraw function from the market contract
    await market.methods.delistNFT().send({ from: walletAddress })
      .once("error", (err) => {
        console.log(err);
        setFeedback2("Sorry, something went wrong while delisting. Please try again.");
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback2("Successfully delisted your suits NFT.");
      });
  };

  useEffect(() => {
    if (connected) {
      fetchTokenIDs();
    }
  }, [connected]);

  useEffect(() => {
    if (connected) {
      checkIfUserIsSeller();
    }
  }, [connected]);

  useEffect(() => {
    if (tokenIDsFetched) {
      if (listNFTRef.current) {
        listNFTRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [tokenIDsFetched]);


return (
  <div>
    <h5 class="space"><b>MANAGE</b></h5>
    <h3 class="dbspace"><b>suits NFT</b></h3>
    {!form ? (
    <div>
      <p className="space"><button className="loginBtn" onClick={connectWallet}>Sign in using Metamask</button></p>
    </div>
    ) : (
    <div>
      <p class="space"><b style={{color: "red"}}>{feedbackmessage}</b></p>

      {tokenIDsFetched && checkIfUserIsSeller && (
      <div>
        <p ref={listNFTRef} class="dbspace">========================</p>
        
        {!isApproved && (
        <div class="dbspace">
          <div class="dbspace">
            <h5 class="space"><b>LIST</b></h5>
            <h3 class="space"><b>suits NFT</b></h3>
          </div>
          <p class="space">Your Balance:<br></br><b>{balance} suits</b></p>
          <p class="space"><button className="loginBtn" onClick={approve}>Approve to List</button></p>
          <p class="tspace"><b style={{color: "red"}}>{feedback}</b></p>
          <p class="tspace">Note: Marketplace deducts a 5% fee at the time of sale.
          <br></br><br></br>By using sooq, you agree to abide by our <a href="/terms">Terms of Service.</a></p>
        </div>
        )}
          
        {isApproved && (
        <div class="dbspace">
          <div class="space">
            <h5 class="space"><b>LIST</b></h5>
            <h3 class="dbspace"><b>suits NFT</b></h3>
            <label><b>Listing Price</b></label>
            <p><input type="number" id="titleInput" value={price} placeholder="Write in your Listing Price." onChange={(e) => setPrice(e.target.value)} style={{ border: "2px solid black", padding: "5px", width: "300px", textAlign: "center" }}/></p>
            <p><b>ETH</b></p>
          </div>
          <p class="space"><button className="loginBtn" onClick={listNFT}>List.</button></p>
          <p class="tspace"><b style={{color: "red"}}>{feedback}</b></p>
          <p class="tspace">Note: Marketplace deducts a 5% fee at the time of sale.
          <br></br><br></br>By using sooq, you agree to abide by our <a href="/terms">Terms of Service.</a></p>
        </div>
        )}

        <p class="dbspace">========================</p>

        <div>
          <div class="dbspace">
            <h5 class="space"><b>DELIST</b></h5>
            <h3 class="dbspace"><b>suits NFT</b></h3>
          </div>

          {seller && (
          <div>
          <p class="space"><button className="loginBtn" onClick={delist}>Delist</button></p>
          <p class="tspace"><b style={{color: "red"}}>{feedback2}</b></p>
          </div>
          )}

          {!seller && (
          <div>
          <p><b style={{color: "red"}}>You have no NFTs listed on the Marketplace.</b></p>
          </div>
          )}
        
        </div>

      </div>
      )}
    </div>
    )}
  </div>
);
}

export default Approve2;
