import React from "react";
import { Provider } from "react-redux";
import { Link } from "react-router-dom";
import Approve from '../functions/Approve';
import Stats from '../functions/Stats';
import Check from '../functions/Check';
import store from "../redux/store";
import img from './images/genesis.png';

function Market() {

  return (
    <div class="main screen">
      <h1 class="space"><b>sooq</b></h1>
      <p class="dbspace">1st marketplace on Kroma</p>
      <h3 class="space"><b>KROMA GENESIS</b></h3>
      <p class="dbspace"><img className="responsive-img" src={img}/></p>
      <p class="dbspace">============================</p>
      <p class="bottom"><Stats/></p>
      <p class="dbspace">============================</p>
      <p class="dbspace"><Provider store={store}><Check/></Provider></p>
      <p class="dbspace">============================</p>
      <p class="dbspace"><Provider store={store}><Approve/></Provider></p>
      <p class="dbspace">============================</p>
      <p class="dbspace"><Link to="/"><button className="loginBtn" onClick={() => window.scrollTo(0, 0)}>Home</button></Link></p>
    </div>
  );
}

export default Market;
