import React from "react";
import img from './images/genesis.png';
import img2 from './images/suits.jpg';
import img3 from './images/create.jpg';
import { NavLink } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};


function Home() {
  return (
    <div class="main">
      <h1 class="space"><b>sooq</b></h1>
      <p class="dbspace">1st marketplace on Kroma</p>
      <h3 class="dbspace"><b>👀 AVAILABLE NFTs</b></h3>
      <div class="dbspace">
        <div className="data-container">
          <div className="data-box">
            <div className="center dbspace">
              <div className="data-section">Kroma Genesis</div>
              <p className="data-section"><img src={img} width='60%'/></p>
              <NavLink className="data-section" to="/genesis" onClick={scrollToTop}><button className="loginBtn">See More</button></NavLink>
            </div>
          </div>
          <div className="data-box">
            <div className="center dbspace">
              <div className="data-section">suits.</div>
              <p className="data-section"><img src={img2} width='60%'/></p>
              <NavLink className="data-section" to="/suits" onClick={scrollToTop}><button className="loginBtn">See More</button></NavLink>
            </div>
          </div>
          <div className="data-box">
            <div className="center dbspace">
              <div className="data-section">Create your NFT</div>
              <p className="data-section"><img src={img3} width='60%'/></p>
              <NavLink className="data-section" to="/create" onClick={scrollToTop}><button className="loginBtn">See More</button></NavLink>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom"></div>
    </div>
  );
}

export default Home;
