import React, { useEffect, useState } from "react";
import Web3 from "web3";

const Check = () => {
  const [volume, setVolume] = useState("");
  const [listings, setListings] = useState([""]);
  const [form, setForm] = useState("");
  const [walletAddress, setWalletAddress] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState(``);

  const web3 = new Web3(`https://api.kroma.network/`);
  const marketAddress = '0xbA4FFD0E82C5000aD290146685bFe9b697115F1b';
  const marketABI = [{
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_tokenId",
        "type": "uint256"
      }
    ],
    "name": "buyNFT",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getNumberOfListings",
    "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
        {
          "internalType": "uint256",
          "name": "_index",
          "type": "uint256"
        }
    ],
    "name": "getListing",
    "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokenId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_price",
          "type": "uint256"
        }
    ],
    "name": "listNFT",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },];
  
  const getTokens = async () => {
    const tokenContract = new web3.eth.Contract(marketABI, marketAddress);
    const vol = await tokenContract.methods.getNumberOfListings().call();
    setVolume(vol);
    const place = [];
      for (let i = 0; i < vol; i++) {
        const listing = await tokenContract.methods.getListing(i).call();
        place.push(listing);
      }
    setListings(place);
  };

  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        const networkId = await window.ethereum.request({ method: 'net_version' });
        const isCustomNetworkAdded = await isCustomNetworkAlreadyAdded();
  
        if (networkId === '255') {
          // Network is already 255, so we can connect directly
          await window.ethereum.enable();
        } else {
          if (isCustomNetworkAdded) {
            // Network is not 255 but custom network is added
            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0xFF' }],
              });
              await window.ethereum.enable();
            } catch (switchError) {
              console.log('User rejected chain switch:', switchError);
              return;
            }
          } else {
            // Network is not 255 and custom network is not added
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0xFF', // The chainId of the network you want to add
                    chainName: 'KROMA', // Name of your custom network
                    nativeCurrency: {
                      name: 'ETH', // Name of your custom currency
                      symbol: 'ETH', // Symbol of your custom currency
                      decimals: 18, // Number of decimals of your custom currency
                    },
                    rpcUrls: ['https://api.kroma.network/'], // Replace with the RPC URL of your custom network
                    blockExplorerUrls: ['https://blockscout.kroma.network/'], // Replace with your block explorer URL
                  },
                ],
              });
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0xFF' }],
              });
              await window.ethereum.enable();
            } catch (addError) {
              console.log('Error adding custom network:', addError);
              return;
            }
          }
        }
  
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        setWalletAddress(accounts[0]);
        setForm('bro');
      }
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    }
  };

  const isCustomNetworkAlreadyAdded = async () => {
    try {
      const chainIdList = await window.ethereum.request({
        method: 'wallet_getEthereumChain',
      });
  
      // Check if the custom network is in the list
      return chainIdList.includes('0xFF'); // Replace '0xFF' with your custom chain ID
    } catch (error) {
      console.error('Error checking for custom network:', error);
      return false;
    }
  };

  const buy = async (e, index) => {
    e.preventDefault();
    const web3 = new Web3(window.ethereum);
    const tokenContract = new web3.eth.Contract(marketABI, marketAddress);
    setFeedbackMessage(`Purchasing, please wait...`);
    try {
      const listing = listings[index];
      const tokenId = listing[1];
      const price = listing[2];
      const receipt = await tokenContract.methods.buyNFT(tokenId).send({ from: walletAddress, value: price });
      console.log(receipt);
      setFeedbackMessage(`Congratulations! You have purchased the NFT.`);
    } catch (err) {
      console.error(err);
      setFeedbackMessage("Sorry, something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getTokens();
  }, []);

  return (
    <div>
      <h5 class="space"><b>PURCHASE</b></h5>
      <h3 class="dbspace"><b>KROMA GENESIS NFT</b></h3>
      <div>
        <div class="spspace">
          {volume === "0" ? <b style={{color: "red"}}>There are currently no KROMA GENESIS NFTs available for sale.</b> :
          <div className="data-container">
            {listings.map((listing, i) => (
            <div key={i} className="data-box">
              <p><b>Token ID:</b> {listing[1]}</p>
              <p><b>Price:</b> {listing[2]/1000000000000000000} ETH</p>
              {form ? (
              <p><button className="loginBtn" onClick={(e) => buy(e, i)}>Scoop it.</button> 🦊</p>
              ) : (
              <p><button className="loginBtn" onClick={connectWallet}>Connect to Scoop</button> 🦊</p>
              )}
            </div>
            ))}
          </div>
          }
        </div>
        <div>
          <p className="tspace">Note: By using sooq, you agree to abide by our <a href="/terms">Terms of Service.</a></p>
          <b style={{color: "red"}}>{feedbackMessage}</b>
        </div>
      </div>
    </div>
    );
}

export default Check;
