import React from "react";

function Terms() {

  return (
    <div class="main screen">
        <div>
            <h1 class="space"><b>sooq</b></h1>
            <p class="dbspace">1st NFT marketplace on Kroma</p>
            <h3 class="space"><b>TERMS OF SERVICE</b></h3>
        </div>
        <div class="terms">
            <p>Last Updated: 5 November 2023</p>
            <p>Please read these terms carefully before using our NFT marketplace.</p>
            <p><strong>1. Acceptance of Terms</strong></p>
            <p>By using sooq, you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please do not use our services.</p>

            <p><strong>2. Eligibility</strong></p>
            <p>You must be at least 18 years old to use our NFT marketplace. By using our services, you represent and warrant that you are of legal age.</p>

            <p><strong>3. Account Registration</strong></p>
            <p>To access certain features of our platform, you may need to use your Metamask account. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account.</p>

            <p><strong>4. Use of Our Services</strong></p>
            <p><strong>a. NFT Listings:</strong> You can list NFTs for sale on our platform. You must ensure that you have the legal right to sell the NFTs and comply with any intellectual property rights.</p>
            <p><strong>b. Prohibited Activities:</strong> You must not engage in any unlawful, fraudulent, or unauthorized use of our services, and you must comply with all applicable laws and regulations.</p>
            <p><strong>c. Content Ownership:</strong> You retain ownership of the NFTs you list. We do not claim any ownership of your NFTs.</p>

            <p><strong>5. Fees </strong></p>
            <p>We may charge fees for listing NFTs or other services. Please refer to our fee schedule for details.</p>

            <p><strong>6. Dispute Resolution</strong></p>
            <p>We encourage users to resolve disputes with one another. However, we may provide dispute resolution services at our discretion.</p>

            <p><strong>7. Intellectual Property</strong></p>
            <p>We respect intellectual property rights. If you believe that your intellectual property rights have been violated, please contact us via Twitter.</p>

            <p><strong>8. Termination</strong></p>
            <p>We reserve the right to terminate your access to our services at our discretion, with or without cause, and with or without notice.</p>

            <p><strong>9. Disclaimer of Warranties</strong></p>
            <p>Our services are provided "as is" without any warranties, express or implied.</p>

            <p><strong>10. Limitation of Liability</strong></p>
            <p>We shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.</p>

            <p><strong>11. Changes to the Terms</strong></p>
            <p>We may update these Terms of Service from time to time. You are responsible for reviewing the most current version.</p>

            <p><strong>12. Contact Us</strong></p>
            <p>If you have any questions or concerns about these Terms of Service, please contact us on Twitter.</p>

            <p>By using our NFT marketplace, you agree to these Terms of Service. Please make sure to review and understand them thoroughly before using our services.</p>
        </div>
    </div>
  );
}

export default Terms;
