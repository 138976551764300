import React from "react";
import { Provider } from "react-redux";
import { Link } from "react-router-dom";
import Approve2 from '../functions/Approve2';
import Stats2 from '../functions/Stats2';
import Check2 from '../functions/Check2';
import store from "../redux/store";
import img from './images/suits.jpg';

function Market2() {

  return (
    <div class="main screen">
      <h1 class="space"><b>sooq</b></h1>
      <p class="dbspace">1st marketplace on Kroma</p>
      <h3 class="space"><b>suits.</b></h3>
      <p class="dbspace"><img className="responsive-img" src={img}/></p>
      <p class="dbspace">============================</p>
      <p class="bottom"><Stats2/></p>
      <p class="dbspace">============================</p>
      <p class="dbspace"><Provider store={store}><Check2/></Provider></p>
      <p class="dbspace">============================</p>
      <p class="dbspace"><Provider store={store}><Approve2/></Provider></p>
      <p class="dbspace">============================</p>
      <p class="dbspace"><Link to="/"><button className="loginBtn" onClick={() => window.scrollTo(0, 0)}>Home</button></Link></p>
    </div>
  );
}

export default Market2;
