import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer class="py-3"  style={{backgroundColor: "#ebecf0"}}>
        <div class="container">
          <p class="m-0 text-center text-black">
            <a href="https://www.twitter.com/sooq_dev" target="_blank">Twitter</a> | <a href="/terms">Terms of Service</a>
            <br></br>
            Copyright &copy; sooq inc. 2023
          </p>
        </div>
      </footer>
    </div>

  );
}

export default Footer;
