import React from "react";
import img from './images/sooqlogo.png';
import { NavLink } from "react-router-dom";


function Navigation() {
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand navbar-light" style={{backgroundColor: "#ebecf0"}}>
        <div className="container navbar_header">
          <NavLink className="navbar-brand" to="/">
          <img className="responsive-img" src={img}/>
          </NavLink>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;