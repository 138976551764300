import React, { useEffect, useState } from "react";
import Web3 from "web3";

const Stats2 = () => {
    const [amount, setAmount] = useState("0");
    const [listingPrices, setListingPrices] = useState([""]);
    const [sales, setSales] = useState("0");
    const [vol, setVol] = useState("0");

    const web3 = new Web3(`https://api.kroma.network/`);
    const contractAddress = "0xFdc82e0b3b72b2c83Be5c3Dc149522A0E0e99f2E";
    const contractABI = [
        {
            "inputs": [],
            "name": "getNumberOfListings",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_index",
                    "type": "uint256"
                }
            ],
            "name": "getListing",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
		    "name": "totalNFTsSold",
		    "outputs": [
			    {
				    "internalType": "uint256",
				    "name": "",
				    "type": "uint256"
			    }
		    ],
		    "stateMutability": "view",
		    "type": "function"
	    },
	    {
		    "inputs": [],
		    "name": "totalValueOfNFTsSold",
		    "outputs": [
			    {
                    "internalType": "uint256",
				    "name": "",
				    "type": "uint256"
			    }
		    ],
		    "stateMutability": "view",
		    "type": "function"
	    },
    ];

    const getTokens = async () => {
        const tokenContract = new web3.eth.Contract(contractABI, contractAddress);
        const listings = await tokenContract.methods.getNumberOfListings().call();
        setAmount(listings);
    };

    const getVol = async () => {
        const tokenContract = new web3.eth.Contract(contractABI, contractAddress);
        const listings = await tokenContract.methods.totalNFTsSold().call();
        setVol(listings);
    };

    const getValue = async () => {
        const tokenContract = new web3.eth.Contract(contractABI, contractAddress);
        const listings = await tokenContract.methods.totalValueOfNFTsSold().call();
        setSales(listings);
    };

    const getListingPrices = async () => {
        const tokenContract = new web3.eth.Contract(contractABI, contractAddress);
        const numListings = await tokenContract.methods.getNumberOfListings().call();
        const prices = [];
        for (let i = 0; i < numListings; i++) {
        const listing = await tokenContract.methods.getListing(i).call();
        prices.push(listing[2]);
        }
        setListingPrices(prices);
    };

    useEffect(() => {
        getTokens();
    }, []);

    useEffect(() => {
        getVol();
    }, []);

    useEffect(() => {
        getValue();
    }, []);

    useEffect(() => {
        getListingPrices();
    }, []);

    const lowestValue = Math.min(...listingPrices);

    return (
        <div>
            <h3 class="space"><b>MARKETPLACE STATS</b></h3>
            <p class="bottom2">suits.</p>
            <div className="data-container">
                <div className="data-box">
                    <div className="data-section">Available for Sale</div>
                    {amount} NFTs
                </div>
                <div className="data-box">
                    <div className="data-section">Floor Price</div>
                    {(lowestValue/1000000000000000000).toFixed(3)} ETH
                </div>
                <div className="empty-box"></div>
                <div className="data-box">
                    <div className="data-section">Total Quantity Sold</div>
                    {vol} NFTs
                </div>
                <div className="data-box">
                    <div className="data-section">Total Value of Sales</div>
                    {(sales/1000000000000000000).toFixed(3)} ETH
                </div>
                <div className="empty-box"></div>
            </div>
            <p class="space"></p>
        </div>
    );
    };

export default Stats2;
